
.dashboardn-background {
    background-image: url('../../assets/assest/bg.png');
    /* background-size: cover; */
    background-repeat: no-repeat;
    min-height: 100vh; 
    justify-content: center;
}

.profile-frame-container {
  position: relative;
  /* width: 150px; 
  height: 150px;  */
  margin: 0 auto;
  text-align: center;
}

.profile-frame {
  position: relative;
  width: 100%;
  height: 100%;
}

/* .profile-image {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  object-fit: cover;
  border-radius: 50%;
  z-index: 1;
} */

.profile-image {
  /* position: absolute; */
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  z-index: 1;
}

/* .profile-pic {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
  left: 0px;
} */

.profile-pic {
  position: absolute;
  width: 125%;
  height: 126%;
  object-fit: contain;
  z-index: 3;
  left: -18px;
  top: -14px;
}

.edit-container {
  position: absolute;
  top: 5px; /* Positioning the icon at the top */
  right: 10px; /* Positioning the icon at the right */
  z-index: 3; /* Ensure the edit icon is above other elements */
}

.editicon {
  width: 25px; /* Adjust size as needed */
  height: 25px; /* Adjust size as needed */
  cursor: pointer;
  border-radius: 50%; /* Make the icon circular */
  background-color: white; /* Optional: background color to enhance visibility */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Optional: add a subtle shadow for better contrast */
  border: 1px solid #ccc; /* Optional: add a border for a clearer boundary */
}

.profileContainer{
  padding-top: 20px;
    align-items: center; 
    justify-content: center;
    font-family: 'Glacial Indifference', sans-serif;
}

.img-fluid {
    width: 100px;
    height: 100px;
}

.icon-title{
    font-size: 16px;
    color: #143149;
    text-align: center;
}

.back-logout {
    width: 50px;
    height: 50px;
}

.title-two {
    font-size: 12px;
    text-align: center;
}

.link {
    text-align: center;
    text-decoration: 'underline'; 
    font-size: '8px';
    color: #212529;
}
/* .whisky-club {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 30px;
    width: 85%;
    background-color: white;
    border-radius: 68px;
    border: 2px solid black;
    padding: 20px;
    align-items: center;
  } */
  
  .profile-container {
    position: relative;
    width: 42%;
    min-width: 250px;
  }
  
  .profile-background-pic {
    width: 251px;
    height: 240px;
    /* margin-left: -68px; */
    margin-top: -27px;
  }
  .user-info1 hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin-top: 1px;
    margin-bottom: -3px;
  }
  .user-info1 b{
    font-size: 11px;
    font-weight:bold;
    color: rgb(10, 37, 60);
  }








  .link {
    font-size: 12px;
  }
  
  .img-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    padding: 0;
    transition: transform 0.3s ease-out;
  }
  
  .img-button:hover {
    transform: scale(1.1) perspective(150px) rotateX(10deg);
  }
  
  .img-fluid {
    display: block;
    width: 200px;
    height: auto;
  }
  
  .back-logout {
    display: block;
    width: 40px;
    height: auto;
    float: right;
    transition: transform 0.3s ease-out;
  }
  
  .back-logout:hover {
    transform: translate3d(0, -5px, -10px) rotateX(20deg);
  }
  

  .gradient-border {
    display: inline-block;
    padding: 5px; /* Padding creates space for the gradient border */
    border-radius: 10px; /* Adjust the border-radius to your preference */
    background: linear-gradient(45deg, #143149, #ffffff, #dee2e5, #143148);
    background-size: 300% 300%; /* Ensures the gradient covers the border */
    animation: gradientAnimation 5s ease infinite; /* Gradient animation */
    margin-bottom: 7px;
  }
  .gradient-border-img {
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 14px 15px rgb(20 49 73), 0 5px 10px #000000c7;
    transition: transform 0.2s, box-shadow 0.2s;
  }

  .gradient-border-img:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 
                0 4px 10px rgba(0, 0, 0, 0.15);
  }
@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.whisky-club1 {
  /* display: flex; */
  margin: 0px 30px;
  /* padding-top: 20px; */
  width: 85%;
  height: auto;
  background-color: white;
  border-radius: 68px;
  border: 2px solid black;
  flex-direction: row;
  align-items: center;
}

.profile-container {
  width: 39%;
  position: relative;
}

/* .user-info1 {
 
  margin-top: -16px;
  margin-left: 10px; 
} */

@media (max-width: 768px) {
  .whisky-club1 {
    flex-direction: column;
    align-items: center;
  }

  .profile-container {
    width: 100%;
    /* display: flex; */
    justify-content: center;
  }
}

  
 
