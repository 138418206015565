

/* @font-face {
    font-family: 'MyCustomFont';
    src: url('../../fonts/Tahoma Regular font.ttf') format('truetype');
  } */

/* .bg{
    background-color: #fff0d9;
} */

.bbg{
    background-color: #ffee83;
}

.box{
    height: 30px;
    width: 30px;
   background-color: #ffee83;
    border-radius: 10px;
    color: white;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    
}

.border{
    background-color: #ffeea3;
}


.btn {
    width: 90px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    padding: 6px;
}
input[type="file"] {
    display: none;
  }
   
.okcheck{
    float: right;
    /* background-color:#c07f1e ; */
    border-radius: 10px;
}

.outerbox{
    border-radius: 10px;
    border: 1px solid black;
    background-color: #ffffff;
}

.uploadimg{
    padding: 50px 50px 14px;
    font-size: 80px;
}


