@import url('https://fonts.googleapis.com/css2?family=Glacial+Indifference&display=swap');

.whiskyBottle{
    width: 50%;
  }

body
{
  /* font-family: 'Glacial Indifference', sans-serif; */
  background-color: #FFFFFF;
  overflow-y:scroll;
}
/* 
@font-face {
  font-family: 'MyCustomFont';
  src: url('../../fonts/Tahoma Regular font.ttf') format('truetype');
} */

.img-box
{
    padding: 10px;
}

.visibleClass
{
  display: block;
}

.notVisibleClass
{
  display: none;
}

.onThnose
{
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 100%;
}