@import url('https://fonts.googleapis.com/css2?family=Glacial+Indifference&display=swap');

.glacial-text {
  font-family: 'Glacial Indifference', sans-serif;
}

.flavour-folio-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.overlay {
  position: absolute;
  bottom: 0px; 
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.logo {
  width: 80%; /* Adjust as needed */
}

.start-button {
  width: 100%;
  background-color: rgb(10, 37, 60);
  border-radius: 15px;
  color: white;
  transition: transform .5s;
  font-size: 22px;
  box-shadow: 0px 2px 4px rgb(0, 0, 0);
}

.start-button:hover {
  background-color: #0a253c; /* Adjust as needed */
}