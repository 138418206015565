/* .bg{
    background-color: #fff0d9;
} */

.btn {
    width: 120px;
    border-radius: 5px;
    color: white;
    font-size: 16px;
}
.mybtn {
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: flex-end; 
}


.multiline-placeholder::placeholder {
    white-space: pre-line;
}

.form-control{
    box-shadow: 2px 2px 2px gray;
   }
.myselected
   {
    color:#C07F1E;
   }
   .myselected1
   {
    color:#C07F1E;
   }
   .content-container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 5%;
    
  }
  .title1{
        color: #212529;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
       
  }
  .left-text{
    font-size: 14px;
    /* margin-top: 20px; */
  }
  .upload_icon{
    width: 60%;
  }
  .custom-textarea {
    border: none;
    outline: none;
    width: 100%;
    height: 30%;
    padding: 10px;
    resize: none; 
    background-color: transparent;
  }
  
  .custom-textarea:focus {
    border: none;
    outline: none;
  }
  .custom-textarea::placeholder {
    color: black; 
    font-size: 15px;
  }

  .custom-textarea::placeholder {
      color: #a9a9a9; /* Replace with your desired color */
      opacity: 1; /* Ensures the color is not faded */
  }

  .outerbox1{
    width: 100%;
    border-radius: 10px;
    border: 1px solid black;
    /* background-color: #ffffff; */
  }
  /* .popup-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .popup-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .popup-image {
    width: 75px;
    object-fit: cover;
    border-radius: 10px;
  } */
  .popup-grid {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two items per row */
    gap: 10px; /* Adjust the gap between items */
  }
  
  .popup-item {
    text-align: center;
  }
  
  .popup-image {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  #useProfilePicture {
    margin-right: 5px;
  }
  
  #useReview {
    margin-right: 5px;
  }
  
  .view-all-btn {
  
    /* margin-left: -87px; */
    border: none;
    background: none;
    width: max-content;
    /* text-decoration: underline; */
    align-self: flex-end;
    /* margin-top: 50px; */
    font-size: 12px;
  }
  .submit-btn{
   
    position:absolute;
    border-radius: 10px;
    background-color: #10263A;
    border: 2px solid #0A253C;
    right: 20%;
    left: 20%;
    font-weight: bold;
    color: white;
    
}
.font{
  font-size:14px
}