
.body{
    font-family: 'Lato', sans-serif !important;
  }
.profilecontainer1{
    height: auto;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.loyalty-points{
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
}
.loyalitypoints-profile{
 position: absolute;
 border-radius:50%;
 left: 1px; 
 top: 6px;
 width: 105px;
 height: 107px;
 border: 2px solid black
}
.voucher-btn{
    float: right;
    position: relative;
    top: -62px;
}