


/* .card-menu{
  padding-left: 15vw !important;
  padding-right: 15vw !important;
}   */
/* .card-menu-item:hover{
  cursor: pointer;
  background-color: #f4c06d83;
  border-radius: 20px;
} */
.main-img{
  border-bottom-right-radius: 13%;
  border-bottom-left-radius: 13%;
  width: 100vw;
  height: 40vh;
}
.menu-img{
  width: 100px;
  height: auto;
  padding: 10px;
  /* margin-left: 33px; */
  /* margin-bottom: 17px; */
}


.menu-img2{
  width: 80px;
  height: auto;
  /* margin-left: -18px;
  margin-bottom: 27px; */
}
.menu-img3{
  width: 80px;
  height: auto;
  /* margin-left: -10px;
  margin-bottom: 17px; */
}
.menu-img4{
  width: 80px;
  height: auto;
  /* margin-left: 13px;
  margin-bottom: 17px; */
}

.card-menu-item{
  cursor: pointer;
}

.card-menu-item-line{
  border-width: 4px;
  padding: 0px 30px 0px 30px;
  border-color: rgb(174, 146, 110);
}

.card-menu-item-line-bottom{
  border-bottom: dashed 10px;
  border-width: 4px;
  border-color: rgb(174, 146, 110);
}

.mbox1{
  height: 20px;
  width: 20px;
  border-radius:100%;
  color: white;
  padding-top: 6px;
  background-color: #327D01;
  font-size: 8px;
  align-items: center;
  justify-content: center;
  
}
.visibleClass
{
 display: block;
}

.notVisibleClass
{
  display: none;
}
