@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

.profile-details{
    text-align: center;
  }
  .body{
    font-family: 'Lato', sans-serif !important;
  }
  .logout{
    border-radius: 5px;
    background-color: #10253A;
    color: white;
    font-size: 14px;
    margin-bottom: 30px;
  }
  /* .user-profile li span {
    font-weight: bold;
    margin-right: 10px;
  }
  .user-profile {
    padding: 20px;
    border-radius: 10px;
  } */
  
 
  .edit-icon {
    border-radius: 50px;
    width: 27px;
    height: 27px;
    cursor: pointer;
    position: absolute;
    top: -115px;
    right: 133px;
  }
.profile {
    display: flex;
  } 
  .smac_img{
    border-bottom-right-radius: 13%;
    border-bottom-left-radius: 13%;
    width: 100%;
  }
  .content{
    padding: 20px ;
    background-color: #F4F6FC;
    border-radius: 10px;
    margin: 18px;
   
  }
.report-heading{
    padding-left: 20px;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 12px;
}
 .reports{
    margin: 10px;
    background-color:#dbdace3d;
    /* #F7F9FD;  */
    /* justify-content: space-between; */
    display: flex;
    border-radius: 10px;
    padding: 13px;
    margin-bottom: 20px;
 }
 

 .reports1{
  text-align: left;
    padding-left: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(221, 221, 221);
    padding-bottom: 10px;
    margin-bottom: 10px;
  /* margin: 18px;
  justify-content: space-between;
  display: flex;
   padding: 13px;
   border-bottom: 1px solid rgb(221, 221, 221);
  background-color:'none'; */
}
 .report-content{
    /* font-family: 'Montserrat', sans-serif; */
    font-size: 16px;
    display: flex;
 }
  .image1 img {
    /* width: 200px;
    height: 50vh; */
    width: 79px;
    height: 59px;
    margin-right: 10px;

  } 
  .profile{
    justify-content: space-between !important;
  }
  .profile-info span h6{
  color: #B1B1B1;
  font-size: 14px;
  /* font-family: 'Lato', sans-serif; */
  }
  .profile-info p{
    /* font-family: 'Lato', sans-serif; */
    color: black;
    font-size: 18px;
  }
  .report-content span p{
  font-size: 12px;
  
  }
  /* .report-content span h6{
    font-size: 14px;
    
    } */
  .profile-img{
    border-radius: 50%;
    position: relative;
    bottom: 19vh;
    height: 142px;
    width: 142px;
  }
 
  .profile-details h2{
    position: relative;
    top: -22vh;
    color: white;
    font-size: 24;
    /* font-family: 'Lato', sans-serif; */
    font: bold;
  }
  .profile-container {
    position: relative;
    display: inline-block; 
}

/* .image img{
    width: 75px;
    height: 75px;
    
} */
.stars {
    unicode-bidi: bidi-override;
    color: gold;
    font-size: 20px;
    width: 72px;
  }
  
  .stars span {
    display: inline-block;
    position: relative;
    width: 11px;
  }
  
  .stars span:before {
    content: "\2605";
    position: absolute;
  }
  
  .stars span:last-child:before {
    content: "\2606";
  }
  
  .stars[data-rating="4.0"] span:nth-child(n+5):before {
    content: "\2606";
  }
  .rating{
    margin-left: 5px;
    color: black;
  }
  .toggle-buttons {
    display: flex;
    justify-content: center;
    margin-top: 90px;
    
  }
  
  .toggle-button {
    padding: 5px 20px;
    margin: 0 5px;
    cursor: pointer;
    border: none;
    background-color:rgb(10, 37, 60);
    border-radius: 5px;
    background-color: white;
    color: black;
    border:2px solid black
    
  }
  
  .toggle-button.active {
    background-color:rgb(10, 37, 60);
    color: white;
  }
  