.whisky-name{
    color: black;
    font-size: 16px;
    padding-left:10px;
   
}
.aromas{
    margin: 18px;
    background-color: #F7F9FD;
    border-radius: 15px;
    padding: 13px;
    margin-bottom: 27px;
  }
  /* .aromas-img::-webkit-scrollbar {
    display: none;
  } */
  
  .aromas-img {
    display: flex;
    flex-wrap: wrap; 
    /* justify-content: space-between;  */
    width: 100%;
    align-items: flex-start;
  }
  
  .image {
    background-color: white;
    border-radius: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex: 0 0 calc(25% - 10px); 
    margin: 4px; 
  }
  
  .image img {
    width: 100%;
    height: auto;
    padding: 5px;
  }
  