
  
  .large-image {
    width: 44px;
    height: 43px;
    border-radius: 25%;
    border: 1px solid rgb(10, 37, 60);
    /* margin-bottom: 10px;  */
  }
  
  .small-image{
    width: 18px;
    height: 18px;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
  }  
  .flavours{
    margin-top:0px;
  }
  .image-grid2{
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: repeat(2, auto); 
  }