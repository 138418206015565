/* body {
  margin: 0;
  font-family: 'Glacial Indifference', sans-serif;
  background-color: #fff0d9;
  overflow-y:scroll;
} */
body{
  background-image: url('../../assets/assest/bg.png');
}
/* .bg{
  background-image: url('../../assets/assest/bg.png');
} */
@font-face {
  /* font-family: 'MyCustomFont'; */
  font-family: 'Lato', sans-serif !important;
  src: url('https://fonts.googleapis.com/css?family=Lato&display=swap');
}

code {
  font-family: 'Lato', sans-serif !important;
}

input[type="file"] {
  display: none;
}

.bg-color
{
  background-color: #F4F6FC;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
}

.addcheck{
  float: right;
}

.add{
  /* background-color: #c07f1e; */
  width: 100px;
  border: none;
  color: white;
  border: 2px solid #0A253C;
  /* padding: 12px 16px; */
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
}

.form-control{
 box-shadow: 2px 2px 2px gray;
}


@media only screen and (min-width: 5391px ){
  
}
.input-shadow{
  box-shadow: 4px 4px 4px gray;
}
.btn-shadow{
  box-shadow: 0px 2px 10px rgb(168, 164, 164);
}