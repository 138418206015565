/* .ardmore {
    background-color: white;
    width: 100%;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    border-radius: 20px;
    border: 2px solid black;
  } */
  
  /* .img-review {
    width: 75%;
    height: auto;
    border-radius: 20px;
  } */
  .img-review {
    /* height: 100%; */
    background: transparent;
    width: 100%;
    border: none;
    object-fit: cover; 
    object-position: center; 
  }
  
  .ardmore-section{
    height: 100%;
    width: 100%;
    overflow: hidden; 
    /* background-color: white; */
    /* width: 40% */
  }

  .im-container{
    display: flex;
    align-items: center;
    /* margin-left: 60px; */
    /* margin-top: -48px; */
  }
  .profile-frame-img{
    width: 140px;
    position: absolute;
    z-index: 1;
    top: -9px;
    left: 9px;
  }
  .profile{
    position: absolute;
    border-radius: 50%;
    left: 20px;
    /* top: 30px; */
    width: 120px;
    height: 120px;
    /* height: 115px; */
    border: 2px solid #163249;

  }
  .large-image{
    width: 122px;
    height: 122px;
    border-radius: 79px;
  }
  .small-images{
    width: 55px;
    /* height: 35px; */
    border-radius: 20%;
    margin: 3px 3px;
    border: 1px solid rgb(10, 37, 60);
  }
  .description{
    text-align: justify;
    font-size: 14px;
    color: rgb(10, 37, 60);
    padding: 10px;
    line-height: 1.5;
    margin-bottom:-15px;
  }
 
  /* .whisky-page-bg{
    background-image: url(../../assets/assest/Theme\ 1.png);
  } */
  .view-al-btn {
    font-size: 12px;
    border: none;
    background: none;
    width: max-content;
    text-decoration: underline;
}
.large-images{
  width: 100%;
  height: auto;
  border-radius: 56px;
  /* margin-bottom: 25px; */
  border: 1px solid rgb(10, 37, 60);
}
.fixed-icon {
  top: 25px;
  position: fixed;
  border-radius: 10px;
  border: none;
  right: 13%;
  left: 72%;
  color: black; /* Text color is set to black */
  font-size: 32px;
  font-weight: 100;
}

/* Ensure that the color remains black on hover */
.fixed-icon:hover {
  color: black; /* Keeps the color black on hover */
}

.close-button {
  position: absolute;
  top: 0px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333; /* Adjust color as needed */
}

.palate-container{
  /* margin-left: 10px; */
}

.nose-container{
  /* margin-left: 10px; */
}

.text-center {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}