/* Overlay background */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Popup container */
.popup {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 90%; /* For mobile: take up 90% of the screen */
  max-width: 400px; /* For desktop: limit the max width */
  margin: 0 auto;
  text-align: center;
}

/* Popup body */
.popup-body {
  margin-bottom: 20px;
}

/* Text styling */
.popup-body p {
  font-size: 16px;
  color: #333;
}

/* Footer with buttons */
.popup-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Buttons styling */
.popup-button {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

/* 'Back Home' button */
.popup-button.stay {
  background-color: #e0e0e0;
  color: #333;
}

/* 'Continue Review' button */
.popup-button.continue {
  background-color: #10263A;
  color: white;
}

/* Hover effects */
.popup-button.stay:hover {
  background-color: #c0c0c0;
}

.popup-button.continue:hover {
  background-color: #10263A;
}
