.update-btn{
   
    /* position: fixed; */
    border-radius: 10px;
    background-color: #10263A;
    border: 2px solid #0A253C;
    /* right: 20%;
    left: 20%; */
    font-weight: bold;
    color: white;
    height: 40px;
    width: 100% ;
}

.profile-container {
    width: 100% !important;
}
.form-control1 {
    margin-left: -3px;
    width: 347px;
    height:53px;
    border-radius: 20px;
    box-shadow: inset 0 0 10px #00000029;
    border:none 
}
  .edit-icon1{
    position: relative;
    top: -116px;
    right: 27px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 50px;
  }
  .cropper-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .cropper-crop-box {
    position: absolute;
    top: 26px;
    right: 117px;
  }
  
  .crop-container {
    position: relative;
    margin-top: 10px;
    width: 100%;
    height: 400px;
    background: #333;
  }
  
  .controls {
    margin-top: 20px;
    /* margin-left: 20px; */
  }
