.user-info hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1px 0;
  }
  .user-info b{
    font-size: 11px;
    font-weight:bold;
    color: rgb(10, 37, 60);
  }
  .text
  {
    text-align: center;
  }
  .icons{
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }
  .icons img{
    width: 25px;
    height: 25px;
  }
  .hr{
    margin: 3rem;
    width: 115%;
    margin-left: -10px;
    margin-top: 20px;
}
.profile-bg{
  background-image: url(../../assets/assest/Newbie\ Male.png);
}
.flavour-container {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh; 
  justify-content: center;
}

.profile-pic-review {
    position: absolute;
    left: -4px;
    top: -2px;
    width: 89px;
    height: 89px;
    border: 2px solid #10263a;
}

.ardmore {
  margin-bottom: 10px;
  /* background-color: white; */
  width: 100%;
  /* display: flex; */
  padding: 10px;
  justify-content: space-between;
  border-radius: 20px;
  border: 2px solid #10263a;
}

.img-review {
  width: 85%;
  /* height: auto; */
  /* border-radius: 20px; */
}



.small-image {
  width: 55px;
  height: auto;
}

.images-section{
  /* background-color: rgb(247, 249, 253); */
  width: 74%;
  /* height: 255px; */
  text-align: center;
  /* padding: 20px; */
  border-radius: 20px;
}

.ardmore-section {
  /* background-color: white; */
  width: 100%;
}
.smac{
  display: flex;
  width: 100%;
  height:120px
}
.v-all{
  height: 20px;
  background-color: #0a253c;
  border-radius: 30px;
  width: auto !important;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  font-size: 10px;
  color: white;
}
.img-name{
  font-weight: bold;
  margin-top: -24px;
  font-size: 14px;
}