.smac_img{
    border-bottom-right-radius: 13%;
    border-bottom-left-radius: 13%;
    width: 100vw;
    /* height: 30vh; */
  }

  /* App.css */

/* Ensure the body takes up full height and removes any margin */
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

/* Set the #root element to mimic a mobile screen size */
#root {
  max-width: 430px; /* Typical mobile screen width */
  margin: 0 auto;   /* Center it in the middle of the desktop screen */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: add some shadow for aesthetics */
  border: 1px solid #ccc; /* Optional: add a border to define edges */
}

  body
  {
    font-family: 'Glacial Indifference', sans-serif;
    font-size: 18px!important;
  }

  .captcha {
    width: 100%;
    /* display: flex; */
    align-items: center;
    align-content: center;
  }
  
  .captcha iframe {
    width: 100% !important;
  }
  

  @font-face {
    font-family: 'MyCustomFont';
    src: url('../../fonts/Tahoma Regular font.ttf') format('truetype');
  }

.smacid-btn{
    background-color: #c07f1e;
    border-radius: 10px;
    width: 100px;
    color: white;
    transition: transform .5s; 
    /* height: 5vh; */
    box-shadow: 0px 2px 5px gray;
    /* position: absolute; */
    /* top: 70vh;
    right: 10vw; */
}
/* .smacid-btn:hover{
    background-color: #c07f1e;
    border-radius: 10px;
    width: 80px;
    color: white;
    height: 7vh;
    box-shadow: 0px 2px 5px gray;
    position: relative;
    top: 8vh;
    right: 7vw;
    transform: scale(0.7)
} */
.smac-input{
    /* width: 57vw; */
    border: 2px solid black;
    background-color: #F4F6FC;
    border-radius: 10px;
    /* margin-left: 7vw; */
}

.captcha{
  padding: 15px 10px 10px 10px; 
}
.btn:hover{
  color: white;
}
@media only screen and (max-width: 768px) {
    .smac-input{
        width: 100%;
        line-height: 35px;
        margin: auto;
    }
}

.container-main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  height: 100vh;
}

.content-main {
  width: 90%;
  max-width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}