.im-container {
    display: flex;
    align-items: center;
  }
  .large-image {
    width: 44px;
    height: 43px;
    border-radius: 25%;
    border: 1px solid rgb(10, 37, 60);
  }
  
  .row-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .im-container1 {
    /* padding-top: 10px; */
    justify-content: center;
    margin-bottom: 2px;
  }
  .im-container {
    display: flex !important;
    align-items: center;
    /* margin-left: 84px; */
    position: relative;
}
  .small-image {
    width: 20px; 
    height:20px;
    border-radius: 20%;
    margin: 0 2px; 
    border: 1px solid rgb(10, 37, 60);
  }
  .im-container{
    /* margin-left: 60px; */
    position: relative;
  }
  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-template-rows: repeat(2, auto);
    gap: 0px; 
  }