.add-btn{
    width: 80%;
    height: 10;
    border-radius: 10%;
    background-color: #c07f1e;
    position: fixed;
    left: 5%;
    right: 5%;
    bottom: 30;
}

.fixed-button {
  position: fixed;
  border-radius: 10px;
  width: auto;
  /* height: 35px; */
  background-color: #10263A;
  border: 2px solid #0A253C;
  bottom: 20px;
  /* right: 20%;
  left: 20%; */
  padding-left: 50px;
  padding-right: 50px;
  font-weight: bold;
  color: white;
}

.truncate {
  width: 100%;
  padding-left: 10px;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

body
{
  /* font-family: 'Lato', sans-serif !important; */
  /* font-family: 'MyCustomFont'!important;  */
  overflow-y:scroll;
}

@font-face {
  font-family: 'MyCustomFont';
  src: url('../../fonts/Tahoma Regular font.ttf') format('truetype');
}

.design_search
{
  border-radius: 10px;
  border: 2px solid black;
  background-color: #F4F6FC;
}

.dot-lines-horizontal
{
    border-top: 4px dotted rgba(0, 0, 0, 0.247);
     width: 80px;
}
.dot-lines-vertical
{
    border-right: 4px dotted rgba(0, 0, 0, 0.247);
    height: 80px;
}
.bottle-image{
    height: 100%;
    width: 80px;
    margin-right: 10px;
}
