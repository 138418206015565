
.circular-layout1{
    position: relative;
    width: 75px;
    height: 0px;
    margin-left: -80px;
    margin-top: 44px;
}
.image-container img {
    border: 1px solid black;    
}
/* .image-container img {
    width: 35px !important;
    height: 6vh !important;
} */
.profile-pic1 {
    border-radius: 50%;
    position: relative;
    bottom: 0vh;
    height: 80px;
    width: 80px;
    margin-right: 21px;
}
