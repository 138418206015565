/* .bg{
    background-color: #fff0d9;
} */

.bbg{
    background-color: #ffee83;
}

.box{
    height: 30px;
    width: 30px;
    background-color: #ffee83;
    border-radius: 10px;
    color: white;
    font-size: 10px;
    padding-top: 2px;
    align-items: center;
    justify-content: center;
    
}


.myfselected
{
    display: block;
}
.unfselect
{
    display: none;
}

.mselected
{
    display: block;
}

.notselected
{
    display: none;
}

@font-face {
    font-family: 'MyCustomFont';
    src: url('../../fonts/Tahoma Regular font.ttf') format('truetype');
  }

  .design_search
  {
    border-radius: 10px;
    border: 2px solid black;
  }


.box1{
    height: 20px;
    width: 20px;
    border-radius:100%;
    color: white;
    padding-top: 4px;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    
}

.box3{
    height: 20px;
    width: 20px;
    border-radius:100%;
    color: white;
    padding-top: 4px;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    
}

.border{
    background-color: #ffeea3;
}


   
.okcheck{
    float: right;
    /* background-color:#c07f1e ; */
    border-radius: 10px;
    justify-content: end;
}

.outerbox{
    border-radius: 10px;
    border: 1px solid black;
    background-color: #F4F6FC;
}

.onThplate
{
  position: fixed;
}


