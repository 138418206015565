.reward-container{
  min-height: 100vh;
}
.reward-page {
    padding: 20px;
    margin-top: 60px;
  }
  
  .reward-summary {
    text-align: left;
    margin-bottom: 57px;
  }
  
  .reward-summary h2 {
    font-size: 20px;
    color: #333;
  }
  
  .reward-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 15px;
  }
  
  .reward-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: auto;
  }
 
  .reward-image img {
    width: 100%;
    height: 70px;
  }
 .reward-header {
  margin-bottom: -10px;
  }
.back-button-container{
  margin-top: 6px;
}

.reward-top {
  text-align: center; 
}

  .reward-header h3 {
    margin-top: 10px;
    font-size: 12px;
    color: #333;
    font-weight: bold;

  }
  .reward-header h3 {
    margin-bottom: 0.5rem;
  }
  
  .liked-text {
    font-size: 10px;
    color: #666;
  }
  
  .reward-brand {
    font-size: 12px;
    color: #888;
  }
  
  .reward-description {
    margin: 0px;
    font-size: 10px;
    color: #666;
  }
  .reward-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.reward-logo {
  padding: 10px;
  text-align: center;
}

.reward-logo img {
  max-width: 100px; 
  height: auto;
}

.reward-content {
  padding: 10px;
}
.liked-text {
  color: #666;
}
.reward-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .reward-divider {
    position: relative;
    height: 40px;
  }
  
  .reward-divider::before {
    content: '';
    display: block;
    width: 100%;
    border-top: 2px solid #ddd; 
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
  }
  
  .reward-logo {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #ddd;
    padding: 1px;
    z-index: 2;
    height:auto;

  }
  
  .reward-logo img {
    width: 37px;
   
  }

  .liked-text {
    color: #666;
  }
  .reward-details-image{
    width: 100%;
  }
  .reward-details-page {
    padding: 20px;
  }
  
  .reward-details-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .reward-details-image {
    max-width: 100%;
    height: auto;
  }
  
  .reward-details-content {
    font-size: 16px;
  }
  .brand-name {
    font-size: 16px; 
    font-weight: bold; 
    margin-left: 20px;
  }
  
  .reward-details {
   padding: 16px;
  } 
  
  .header-actions {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    gap: 12px;
  }
  
  .sponsored, .share-icon, .menu-icon {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 8px;
    border-radius: 50%;
  }
  
  .reward-info {
    padding: 16px 0;
  }

  .brand-logo-img {
    width: 80px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #000;
    object-fit: contain;
    margin-right: 10px;
  }
  
  .brand-name {
    font-weight: bold;
    font-size: 16px;
  }
  
  .reward-title {
    font-size: 20px;
    font-weight: bold;
    margin: 12px 0;
  }
  
  .likes-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
  }
  
  .likes-count {
    font-size: 14px;
    color: gray;
  }
  
  .like-dislike-buttons {
    display: flex;
    gap: 12px;
  }
  
  .like-btn, .dislike-btn {
    background-color: rgba(0, 0, 0, 0.05);
    border: none;
    border-radius: 50%;
    padding: 8px;
  }
  
  .get-code-btn{
    position: relative;
    border-radius: 10px;
    background-color: #10263A;
    border: 2px solid #0A253C;
    width: 100%;
    font-weight: bold;
    color: white;
    height: 40px;  
    left: -5px;
}
  .reward-details-section h3 {
    margin-top: 35px;
    font-size: 18px;
  }
  
  .reward-details-section li {
    margin-bottom: 5px;
    font-size: 16px;
  }
  
  .brand-logo {
    display: flex;
    align-items: center; 
    gap: 15px;
  }
  
  .brand-logo-img {
    background: white;
    width: 40px;
    height: 40px;
  }
  
  .brand-name {
    font-weight: bold;
    font-size: 14px;
  }
  .reward-brand {
    margin: 0; 
    padding: 0; 
    margin-top: 11px;
  }
 
  .reward-details-image{
    margin-top: 73px;
    width: 100%;
    height: 25vh;
    border-radius: 20px;
  }
  .terms-header {
    font-weight: bold;
    /* border-bottom: 1px solid #ccc; */
    padding-bottom: 5px;
  }
  
  .terms-list {
    font-size: 16px;
    margin-top: 10px;
    padding-left: 15px;
  }
  
  .terms-list li {
    margin-bottom: 5px;
  }
  .redeem-details p {
    font-size: 16px;
  }
  .points{
    font-size: 16px;
  }